import { useEffect, useRef, useState } from 'react';
import { throttleWrapper } from '@util/eventHandling';

const useDropdown = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };
  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };

  const handleButtonClick = () => {
    if (isDropdownOpen) {
      handleDropdownClose();
    } else {
      handleDropdownOpen();
    }
  };

  const handleScrollBody = throttleWrapper(() => setIsDropdownOpen(false), 1000);
  useEffect(() => {
    const handleDropdownHide = (e) => {
      if (buttonRef.current && !buttonRef.current.contains(e.target) && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        handleDropdownClose();
      }
    };
    document.addEventListener('mousedown', handleDropdownHide);
    return () => {
      document.removeEventListener('mousedown', handleDropdownHide);
    };
  }, []);

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('scroll', handleScrollBody);
    }
    return () => {
      document.removeEventListener('scroll', handleScrollBody);
    };
  }, [isDropdownOpen]);

  return { buttonRef, dropdownRef, isDropdownOpen, handleButtonClick, handleDropdownOpen, handleDropdownClose };
};

export default useDropdown;
