// 확장자 코드 리스트

export const SORT_CODE = {
  NEWEST: 'NEWEST',
  POPULARITY: 'POPULARITY',
  RECOMMENDED: 'RECOMMENDED',
  ACCURACY: 'ACCURACY',
  LOWER_PRICE: 'LOWER_PRICE',
  HIGHER_PRICE: 'HIGHER_PRICE',
} as const;
