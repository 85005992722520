import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { CellMeasurerCache } from 'react-virtualized';
import { LanguageCodeEnum, ProductCardsWithBrandQuery, useProductCardsWithBrandQuery } from 'src/generated/graphql';
import * as ga from '@lib/ga';
import { SummaryProducts } from '@components/common/Products/type';
import { filterAdult, filterApplication, filterCategory, filterExtension, filterPrice, sortProducts } from '@components/common/Products/action';
import { useStatefulUrl } from '@hooks/useStatefulUrl';
import useExchange from '@hooks/useExchange';
import { sortOrderEventLog, filterEventLog } from '@lib/mixpanel/events';
import { SORT_CODE } from '@components/filter/constants';
import { LanguageCode, PAGE_NAME } from '../constants';

type Props = {
  pageType: PAGE_NAME;
  summaryProducts: SummaryProducts;
  nocache?: boolean;
  sort?: string;
  isFreeSort?: boolean;
  defaultLimit?: number;
  isAdult?: boolean;
};

const cache = new CellMeasurerCache({
  defaultHeight: 377,
  fixedWidth: true,
});

export default function useProduct({ pageType, summaryProducts = [], nocache, sort, isFreeSort = true, defaultLimit = 18, isAdult = false }: Props) {
  const { i18n } = useTranslation();

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [products, setProducts] = useState<ProductCardsWithBrandQuery['productCards'] | null>(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(defaultLimit);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedSortOrder, setSelectedSortOrder] = useState<string | null>(sort);
  const [selectedExtensions, setSelectedExtensions] = useStatefulUrl<string[]>(
    [],
    'extensions',
    (s) => s.join(','),
    (str) => str.split(','),
  );
  const [selectedApplications, setSelectedApplications] = useStatefulUrl<string[]>(
    [],
    'applications',
    (s) => s.join(','),
    (str) => str.split(','),
  );
  const [selectedMinPrice, setSelectedMinPrice] = useStatefulUrl<number | null>(
    null,
    'min-price',
    (s) => s.toString(),
    (str) => Number(str),
  );
  const [selectedMaxPrice, setSelectedMaxPrice] = useStatefulUrl<number | null>(
    null,
    'max-price',
    (s) => s.toString(),
    (str) => Number(str),
  );
  const [isShowAdultProduct, setIsShowAdultProduct] = useStatefulUrl<boolean | null>(
    isAdult,
    'adult',
    (s) => (s ? 'y' : 'n'),
    (str) => str === 'y',
  );
  const [tmpMinPrice, setTmpMinPrice] = useState<number | null>(null);
  const [tmpMaxPrice, setTmpMaxPrice] = useState<number | null>(null);
  const [selectedPriceRangeId, setSelectedPriceRangeId] = useState<number | null>(null);

  // 이슈 발생하여 로직 수정
  // 관련 이슈 지라 티켓: https://carpenstreet.atlassian.net/browse/ACON-409
  useEffect(() => {
    setSelectedSortOrder(sort);
  }, [sort]);

  // statefulUrl이 변경되면,
  // 1. onClearProducts 실행
  // 2. tmpMinPrice, tmpMaxPrice, selectedPriceRangeId 설정
  useEffect(() => {
    onClearProducts();
    if (selectedMinPrice) setTmpMinPrice(selectedMinPrice);
    if (selectedMaxPrice) setTmpMaxPrice(selectedMaxPrice);
    const multiply = i18n.language === 'ko' ? 1 : i18n.language === 'ja' ? 10 : i18n.language === 'zh' ? 100 : 1000;
    const multipliedMinPrice = selectedMinPrice ? selectedMinPrice * multiply : null;
    const multipliedMaxPrice = selectedMaxPrice ? selectedMaxPrice * multiply : null;
    if (!multipliedMinPrice && multipliedMaxPrice === 5000) setSelectedPriceRangeId(0);
    else if (multipliedMinPrice === 5000 && multipliedMaxPrice === 10000) setSelectedPriceRangeId(1);
    else if (multipliedMinPrice === 10000 && multipliedMaxPrice === 30000) setSelectedPriceRangeId(2);
    else if (multipliedMinPrice === 30000 && multipliedMaxPrice === 50000) setSelectedPriceRangeId(3);
    else if (multipliedMinPrice === 50000 && multipliedMaxPrice === 70000) setSelectedPriceRangeId(4);
    else if (multipliedMinPrice === 70000 && multipliedMaxPrice === 100000) setSelectedPriceRangeId(5);
    else if (multipliedMinPrice === 100000 && !multipliedMaxPrice) setSelectedPriceRangeId(6);
  }, [selectedExtensions, selectedApplications, selectedMinPrice, selectedMaxPrice, selectedSortOrder]);

  const onClearProducts = () => {
    setProducts([]);
    setOffset(0);
    setLimit(defaultLimit);
    cache.clearAll();
  };

  const onAddProducts = (productList: ProductCardsWithBrandQuery['productCards']) => {
    if (!products || products.length === 0) {
      setProducts(productList);
    } else {
      // 중복된 상품 제거. 조회 시점이 차이가 나면 중복된 상품이 존재할 수 있다. 특히 SSR에서 내려온 정보를 사용할 때
      const existingGoods = products.map((item) => item?.id).filter((e) => Boolean(e));
      const existingGoodsSet = new Set(existingGoods);
      const dedupNewProducts = productList.filter((item) => item?.id && !existingGoodsSet.has(item.id));
      setProducts([...products, ...dedupNewProducts]);
    }
  };

  const onSelectExtensions = (extension: string) => {
    onClearProducts();
    const extensionName = extension[0] === '.' ? extension.slice(1) : extension;
    const selectedExtensionsSet = new Set(selectedExtensions);
    if (selectedExtensionsSet.has(extensionName)) {
      setSelectedExtensions(selectedExtensions.filter((element) => element !== extensionName));
    } else {
      setSelectedExtensions([...selectedExtensions, extensionName]);
    }

    filterEventLog({
      extensionValue: extensionName,
      extensionType: selectedExtensionsSet.has(extensionName) ? 'unchecked' : 'checked',
      pageName: pageType,
    });
  };

  const onSelectApplications = (application: string) => {
    onClearProducts();
    const selectedApplicationsSet = new Set(selectedApplications);
    if (selectedApplicationsSet.has(application)) {
      setSelectedApplications(selectedApplications.filter((element) => element !== application));
    } else {
      setSelectedApplications([...selectedApplications, application]);
    }

    filterEventLog({
      applicationValue: application,
      applicationType: selectedApplicationsSet.has(application) ? 'unchecked' : 'checked',
      pageName: pageType,
    });
  };

  const onSelectMinPrice = (price: number | null) => {
    onClearProducts();
    setSelectedMinPrice(price);

    filterEventLog({
      minPrice: price,
      pageName: pageType,
    });
  };

  const onSelectMaxPrice = (price: number | null) => {
    onClearProducts();
    setSelectedMaxPrice(price);

    filterEventLog({
      maxPrice: price,
      pageName: pageType,
    });
  };

  const onSelectSortOrder = (sortOrder: (typeof SORT_CODE)[keyof typeof SORT_CODE] | null) => {
    if (selectedSortOrder === sortOrder) return;
    onClearProducts();
    setSelectedSortOrder(sortOrder);

    sortOrderEventLog({
      sortType: sortOrder,
      pageName: pageType,
    });
  };

  const onSelectCategory = (category: string) => {
    onClearProducts();
    setSelectedCategory(category);
  };

  const onClearFilters = () => {
    onClearProducts();
    setSelectedExtensions([]);
    setSelectedApplications([]);
    onSelectMinPrice(null);
    onSelectMaxPrice(null);
    setTmpMinPrice(null);
    setTmpMaxPrice(null);
    setSelectedPriceRangeId(null);

    filterEventLog({
      filterReset: 'y',
      pageName: pageType,
    });
  };

  const onPriceChipUnCheck = () => {
    onSelectMinPrice(null);
    onSelectMaxPrice(null);
    setTmpMinPrice(null);
    setTmpMaxPrice(null);
    setSelectedPriceRangeId(null);
  };

  const onToggleShowAdult = () => {
    onClearProducts();
    setIsShowAdultProduct(!isShowAdultProduct);
  };

  const isFilterOn = selectedExtensions.length !== 0 || selectedApplications.length !== 0 || Boolean(selectedMinPrice) || Boolean(selectedMaxPrice);

  const getFilterCategoryProducts = (categoryCode: string) => {
    if (!categoryCode) return summaryProducts;
    return filterCategory(summaryProducts, categoryCode);
  };

  const getFilterExtensionProducts = (extensions: string[]) => {
    if (!extensions && extensions.length === 0) return summaryProducts;
    return filterExtension(summaryProducts, extensions);
  };

  const nextOffset = () => {
    if (Boolean(isProductsLoading) || isLoading) return;
    setIsLoading(true);
    setOffset((prev) => prev + 1);
  };

  const { ex } = useExchange(i18n.language as LanguageCode);
  const convertPrice = (price: number) => ex(price).display.amount;

  const filterProductsNos = (() => {
    let products = [...summaryProducts];
    if (!products) return [];
    if (selectedCategory) products = filterCategory(products, selectedCategory);
    if (selectedExtensions.length > 0) products = filterExtension(products, selectedExtensions);
    if (selectedApplications.length > 0) products = filterApplication(products, selectedApplications);
    products = filterPrice(products, selectedMinPrice, selectedMaxPrice, convertPrice);
    if (selectedSortOrder) products = sortProducts(products, selectedSortOrder, isFreeSort);
    products = filterAdult(products, isShowAdultProduct);
    return products.map((e) => e.id || 0);
  })();
  const filterCount = filterProductsNos.length;
  const sliceProductsNos = filterProductsNos.slice(offset * limit, (offset + 1) * limit);

  const { loading, error, data } = useProductCardsWithBrandQuery({
    ...(nocache ? { fetchPolicy: 'no-cache' } : {}),
    variables: {
      languageCode: i18n.language as LanguageCodeEnum,
      productNos: sliceProductsNos,
    },
    skip: sliceProductsNos.length === 0 || !selectedSortOrder,
  });
  const isProductsLoading = loading || error;
  const productCard = !isProductsLoading && data?.productCards;

  useEffect(() => {
    if (productCard && productCard.length > 0) {
      onAddProducts(productCard);
      setIsLoading(false);
    }

    if (!isProductsLoading && summaryProducts.length === 0) setIsLoading(false);
  }, [productCard, isProductsLoading, selectedExtensions, selectedApplications, selectedMinPrice, selectedMaxPrice, selectedSortOrder, selectedCategory, isShowAdultProduct]);

  useEffect(() => {
    // 클라이언트에서만 실행
    if (typeof window !== 'undefined') {
      if (typeof products !== 'undefined' && products && products.length > 0) {
        const viewItems = products.map((product, idx) => {
          return {
            id: product.id,
            name: product.title,
            list_name: `CardFilterGroup ${pageType}`,
            brand: product.brand.code,
            list_position: idx,
            price: product.price,
          };
        });

        ga.event({
          ecommerce: {
            impressions: viewItems,
          },
        });
      }
    }
  }, [products]);

  return {
    selectedExtensions,
    setSelectedExtensions,
    selectedApplications,
    setSelectedApplications,
    selectedMinPrice,
    onSelectMinPrice,
    setSelectedMinPrice,
    tmpMinPrice,
    setTmpMinPrice,
    selectedMaxPrice,
    onSelectMaxPrice,
    setSelectedMaxPrice,
    tmpMaxPrice,
    setTmpMaxPrice,
    selectedPriceRangeId,
    setSelectedPriceRangeId,
    onSelectSortOrder,
    filterCount,
    products,
    offset,
    limit,
    cache,
    isLoading: Boolean(isProductsLoading) || isLoading || (sliceProductsNos.length > 0 && (products || []).length === 0),
    onSelectExtensions,
    onSelectApplications,
    onSelectCategory,
    onAddProducts,
    onClearProducts,
    getFilterCategoryProducts,
    getFilterExtensionProducts,
    nextOffset,
    selectedSortOrder,
    onClearFilters,
    onPriceChipUnCheck,
    isShowAdultProduct,
    onToggleShowAdult,
    isFilterOn,
  };
}
