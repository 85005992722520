import React from 'react';
import { CustomText } from '@components/ui';
import * as Styled from './styled';

type Props = {
  title: string;
  description?: string;
};

export default function NoItemsMessage({ title, description }: Props) {
  return (
    <Styled.NoItemsContainer>
      <Styled.NoItemsTextWrapper>
        <CustomText style={{ width: '100%', padding: '10px 0px' }}>{title}</CustomText>
        {description && <CustomText style={{ width: '100%', padding: '10px 0px' }}>{description}</CustomText>}
      </Styled.NoItemsTextWrapper>
    </Styled.NoItemsContainer>
  );
}
