import { ThemeOptions } from '@mui/material';
import styled from 'styled-components';

export const DropdownContentWrapper = styled('div')((props) => {
  const theme = props.theme as ThemeOptions;
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    '.price-input-wrapper': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 16,
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      '.price-input-content': {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        '.input-wrapper': {
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          input: {
            width: '100%',
            height: 36,
            padding: 8,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            borderRadius: 4,
            background: 'rgba(145, 158, 171, 0.12)',
            color: '#212b36',
            fontWeight: '600',
            fontSize: '14',
            lineHeight: '20px',
          },
        },
      },
    },
    '.checkboxes-wrapper': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      gap: 12,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      '.checkboxes-col': {
        display: 'flex',
        flexDirection: 'column',
        width: 165,
        height: 132,
        gap: 12,
      },
    },
  };
});

export const DropdownContentWrapper222 = styled.div`
  .price-input-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: ${(props: any) => (props.theme.breakpoints.down('sm') ? 'column' : 'row')};
    gap: 16px;

    .price-input-content {
      display: flex;
      align-items: center;
      gap: 8px;
      .input-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        input {
          width: 100%;
          height: 36px;
          padding: 8px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-radius: 4px;
          background: rgba(145, 158, 171, 0.12);
          color: #212b36;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .checkboxes-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .checkboxes-col {
      display: flex;
      flex-direction: column;
      width: 165px;
      height: 132px;
      gap: 12px;
    }
  }
`;

export const FilterApplyButton = styled.button<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  border-radius: 4px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  flex-basis: 0;
  white-space: pre;
  background: ${(props) => (props.isDisabled ? '#D2D2D3' : '#4e4eff')};
`;

export const RadioLabel = styled.label`
  display: flex;
  height: 24px;
  align-items: center;
  cursor: pointer;

  input[type='checkbox'] {
    visibility: hidden;
    width: 0px;
    height: 0px;
  }
  .radio {
    display: inline-block;
  }
  .label-text {
    margin-left: 8px;
  }
`;
