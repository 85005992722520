import { IDropdownProps } from '@components/search/common/common.types';

export const buttonHeight = 36;
export const buttonGap = 12;

export const getDropdownProps = (x: number, y: number): IDropdownProps => ({
  offsetLeft: x,
  offsetTop: y,
  dropdownHeight: '232px',
  dropdownWidth: '405px',
});
