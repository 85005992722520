import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { Checkbox, DropDown, FilterButton } from '@components/search/common/common';
import { IButtonProps } from '@components/search/common/common.types';
import { CustomText, Skeleton } from '@components/ui';
import { IApplicationsFilterProps } from '@components/search/filters/ApplicationsFilter/ApplicationsFilter.types';
import { ErrorTextWrapper, FilterWrapper } from '@components/search/common/common.styles';
import useDropdown from '@hooks/useDropdown';
import { CheckboxColWrapper } from './ApplicationsFilter.styles';
import { buttonGap, buttonHeight, getDropdownProps } from './ApplicationsFilter.constants';

const ApplicationsFilter = (props: IApplicationsFilterProps) => {
  const { buttonRef, dropdownRef, isDropdownOpen, handleButtonClick } = useDropdown();
  const [dropdownProps, setDropdownProps] = useState(null);
  const { t } = useTranslation(['search']);

  const { loading, error, data } = props;
  const applications = data?.getApplications || [];

  const dropdownContent = error ? (
    <ErrorTextWrapper>
      <CustomText size={14} lineHeight={'20px'} color={'#313135'}>
        {t('search:filters.extensionsLoadErrorText')}
      </CustomText>
      <CustomText weight={600} size={14} lineHeight={'20px'} color={'#313135'}>
        {t('search:filters.refreshInstruction')}
      </CustomText>
    </ErrorTextWrapper>
  ) : (
    <CheckboxColWrapper>
      {applications.map((application) =>
        loading ? (
          <Skeleton height={'24px'} width={'140px'} key={application.id} />
        ) : (
          <Checkbox onChange={props.onChange} isActive={props.selectedApplications.includes(application.name)} labelText={application.name} key={application.id} />
        ),
      )}
    </CheckboxColWrapper>
  );

  const filterButtonProps: IButtonProps = {
    height: buttonHeight,
    isActive: (props.selectedApplications && props.selectedApplications.length > 0) || isDropdownOpen,
    onClick: handleButtonClick,
  };

  useEffect(() => {
    if (buttonRef.current) {
      const { x, y, width } = buttonRef.current.getBoundingClientRect();
      const positionX = window.innerWidth / 2 > x + width ? x : 16;
      const positionY = y + buttonHeight + buttonGap;
      setDropdownProps(getDropdownProps(positionX, positionY, error));
    }
  }, [isDropdownOpen]);

  return (
    <FilterWrapper>
      <FilterButton ref={buttonRef} {...filterButtonProps}>
        {t('search:filters.software')}
      </FilterButton>
      {isDropdownOpen && (
        <DropDown ref={dropdownRef} {...dropdownProps}>
          {dropdownContent}
        </DropDown>
      )}
    </FilterWrapper>
  );
};

export default ApplicationsFilter;
