import { IDropdownProps } from '@components/search/common/common.types';

export const buttonHeight = 36;
export const buttonGap = 12;

export const getDropdownProps = (x: number, y: number, error?: unknown): IDropdownProps => ({
  offsetLeft: x,
  offsetTop: y,
  dropdownHeight: error ? 'max-content' : '290px',
  dropdownWidth: error ? 'max-content' : '468px',
  overflow: 'scroll',
});
