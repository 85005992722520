import { SORT_CODE } from '@components/filter/constants';

export const SORT_ORDERS = [
  {
    key: SORT_CODE.POPULARITY,
    value: 'search:sortOrders.POPULARITY',
  },
  {
    key: SORT_CODE.NEWEST,
    value: 'search:sortOrders.NEWEST',
  },
  {
    key: SORT_CODE.LOWER_PRICE,
    value: 'search:sortOrders.LOWER_PRICE',
  },
  {
    key: SORT_CODE.HIGHER_PRICE,
    value: 'search:sortOrders.HIGHER_PRICE',
  },
  {
    key: SORT_CODE.ACCURACY,
    value: 'search:sortOrders.ACCURACY',
  },
  {
    key: SORT_CODE.RECOMMENDED,
    value: 'search:sortOrders.RECOMMENDED',
  },
];
