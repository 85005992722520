import React from 'react';
import { Chip } from '@components/search/common/common';
import ClearFilters from '@components/search/filters/ClearFilters/ClearFilters';
import { IChipsProps } from '@components/search/filters/Chips/Chips.types';
import { ChipsWrapper } from '@components/search/filters/Chips/Chips.styles';
import { CHIP_TYPES } from '@components/search/filters/Chips/Chips.constants';

const Chips = (props: IChipsProps) => {
  const handleClearFiltersClick = () => {
    props.onClearFilters();
  };

  const handleChipClick = (chipInfo: { chipText: string; chipType: CHIP_TYPES }) => {
    switch (chipInfo.chipType) {
      case CHIP_TYPES.EXTENSION:
        props.onSelectExtensions(chipInfo.chipText);
        break;
      case CHIP_TYPES.APPLICATION:
        props.onSelectApplications(chipInfo.chipText);
        break;
      case CHIP_TYPES.PRICE:
        props.onPriceChipUnCheck();
        break;
    }
  };

  return (
    <ChipsWrapper>
      <div className={'chips-wrapper'}>
        {props.chipsList.map((chipInfo) => (
          <Chip
            key={chipInfo.chipText}
            onClick={() => {
              handleChipClick(chipInfo);
            }}
          >
            {chipInfo.chipText}
          </Chip>
        ))}
      </div>
      <div className={'button-wrapper'}>
        <ClearFilters onClick={handleClearFiltersClick} />
      </div>
    </ChipsWrapper>
  );
};

export default Chips;
