/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { CustomText } from '@components/ui';
import { PAGE_NAME } from 'src/constants';
import { FiltersFlex, ResultCountFlex, SortFlex } from '@components/search/common/common.styles';
import ExtensionsFilter from '@components/search/filters/ExtensionsFilter/ExtensionsFilter';
import ApplicationsFilter from '@components/search/filters/ApplicationsFilter/ApplicationsFilter';
import PriceFilter from '@components/search/filters/PriceFilter/PriceFilter';
import Sort from '@components/search/Sort/Sort';
import { numberWithCommas } from '@lib';
import { IApplicationsFilterProps } from '@components/search/filters/ApplicationsFilter/ApplicationsFilter.types';
import { IExtensionsFilterProps } from '@components/search/filters/ExtensionsFilter/ExtensionsFilter.types';
import { IPriceFilterProps } from '@components/search/filters/PriceFilter/PriceFilter.types';
import Chips from '@components/search/filters/Chips/Chips';
import { CHIP_TYPES } from '@components/search/filters/Chips/Chips.constants';
import { IChipsProps, TChipsList } from '@components/search/filters/Chips/Chips.types';
import SearchBrand from '../../src/section/search/SearchBrand';
import { useGetApplicationsQuery, useGetExtensionConfigsQuery } from '../../src/generated/graphql';
import * as Styled from './styled';

type Props = {
  pageType?: PAGE_NAME;
  selectedExtensions?: string[];
  selectedApplications?: string[];
  totalCount: number;
  brandName?: string;
  brandCode?: string;
  keyword?: string;
  priceFilterProps?: IPriceFilterProps;
  setSelectedExtensions?: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedApplications?: React.Dispatch<React.SetStateAction<string[]>>;
  setTmpMinPrice?: React.Dispatch<React.SetStateAction<number>>;
  setTmpMaxPrice?: React.Dispatch<React.SetStateAction<number>>;
  setSelectedPriceRangeId?: React.Dispatch<React.SetStateAction<number>>;
  onSelectSortOrder?: (sortOrder: string) => void;
  onSelectExtensions?: (extension: string) => void;
  onSelectApplications?: (application: string) => void;
  tmpMinPrice?: number | null;
  onSelectTmpMinPrice?: (value: number | null) => void;
  selectedMinPrice?: number | null;
  setSelectedMinPrice?: (newState: number) => void;
  onSelectMinPrice?: (value: number | null) => void;
  tmpMaxPrice?: number | null;
  onSelectTmpMaxPrice?: (value: number | null) => void;
  selectedMaxPrice?: number | null;
  setSelectedMaxPrice?: (newState: number) => void;
  onSelectMaxPrice?: (value: number | null) => void;
  selectedPriceRangeId?: number | null;
  onSelectPriceRangeId?: (id: number | null) => void;
  selectedSortOrder?: string | null;
  onClearProducts?: () => void;
  onClearFilters: () => void;
  onPriceChipUnCheck: () => void;
  isFilterOn: boolean;
  filterCount: number;
};

export default function ProductFilter({
  keyword,
  brandName,
  brandCode,
  selectedExtensions,
  selectedApplications,
  selectedMinPrice,
  selectedMaxPrice,
  totalCount,
  onSelectExtensions,
  onSelectSortOrder,
  setTmpMinPrice,
  setTmpMaxPrice,
  setSelectedPriceRangeId,
  onSelectApplications,
  tmpMinPrice,
  onSelectMinPrice,
  tmpMaxPrice,
  onSelectMaxPrice,
  selectedPriceRangeId,
  pageType,
  selectedSortOrder,
  onClearProducts,
  onClearFilters,
  onPriceChipUnCheck,
  filterCount,
  isFilterOn,
}: Props) {
  const { t, i18n } = useTranslation(['brand', 'category', 'search']);
  const language = i18n.language;
  const dropMenuRef = useRef<HTMLDivElement>(null);
  const [isDisplayDropMenu, setIsDisplayDropMenu] = useState<boolean>(false);

  // 허브에 등록된 확장자 리스트
  const { data: extensionData, loading: extensionLoading, error: extensionError } = useGetExtensionConfigsQuery();

  // 허브에 등록된 응용 프로그램 리스트
  const { data: applicationsData, loading: applicationsLoading, error: applicationsError } = useGetApplicationsQuery();

  // 마이페이지 메뉴 외에 다른 곳을 클릭한 경우 마이페이지 메뉴 닫음.
  const handleOutSideClick = (event) => {
    if (!dropMenuRef.current.contains(event.target)) setIsDisplayDropMenu(false);
  };

  // 바깥 영역을 클릭하면 드랍다운 메뉴 숨김
  useEffect(() => {
    if (isDisplayDropMenu) {
      // document 클릭 이벤트
      document.addEventListener('click', handleOutSideClick, true);
      return () => {
        document.removeEventListener('click', handleOutSideClick, true);
      };
    }
  });

  const extensionsFilterProps: IExtensionsFilterProps = {
    data: extensionData,
    loading: extensionLoading,
    error: extensionError,
    onChange: onSelectExtensions,
    selectedExtensions: selectedExtensions,
  };

  const applicationsFilterProps: IApplicationsFilterProps = {
    data: applicationsData,
    loading: applicationsLoading,
    error: applicationsError,
    onChange: onSelectApplications,
    selectedApplications: selectedApplications,
  };

  const priceFilterProps: IPriceFilterProps = {
    tmpMinPrice: tmpMinPrice,
    onSelectTmpMinPrice: setTmpMinPrice,
    selectedMinPrice: selectedMinPrice,
    onSelectMinPrice: onSelectMinPrice,
    tmpMaxPrice: tmpMaxPrice,
    onSelectTmpMaxPrice: setTmpMaxPrice,
    selectedMaxPrice: selectedMaxPrice,
    onSelectMaxPrice: onSelectMaxPrice,
    selectedPriceRangeId: selectedPriceRangeId,
    onSelectPriceRangeId: setSelectedPriceRangeId,
  };

  let chipsList: TChipsList;
  const selectedExtensionChips = selectedExtensions.map((x) => ({ chipText: x, chipType: CHIP_TYPES.EXTENSION }));
  const selectedApplicationChips = selectedApplications.map((x) => ({ chipText: x, chipType: CHIP_TYPES.APPLICATION }));
  if (selectedMinPrice || selectedMaxPrice) {
    const priceUnit = language === 'ko' ? '원' : language === 'en' ? '$' : language === 'zh' ? '¥' : '円';

    const priceChipTextConverter = () => {
      if (selectedMinPrice && !selectedMaxPrice) {
        return language === 'ko' ? `${selectedMinPrice.toLocaleString('ko-KR')}${priceUnit} ~` : `${priceUnit}${selectedMinPrice.toLocaleString('ko-KR')} ~`;
      }
      if (!selectedMinPrice && selectedMaxPrice) {
        return language === 'ko' ? `~ ${selectedMaxPrice.toLocaleString('ko-KR')}${priceUnit}` : `~ ${priceUnit}${selectedMaxPrice.toLocaleString('ko-KR')}`;
      }
      return language === 'ko'
        ? `${selectedMinPrice.toLocaleString('ko-KR')}${priceUnit} - ${selectedMaxPrice.toLocaleString('ko-KR')}${priceUnit}`
        : `${priceUnit}${selectedMinPrice.toLocaleString('ko-KR')} - ${priceUnit}${selectedMaxPrice.toLocaleString('ko-KR')}`;
    };
    const priceChipText = priceChipTextConverter();
    chipsList = [...selectedExtensionChips, ...selectedApplicationChips, { chipText: priceChipText, chipType: CHIP_TYPES.PRICE }];
  } else {
    chipsList = [...selectedExtensionChips, ...selectedApplicationChips];
  }
  const chipsProps: IChipsProps = {
    pageType: pageType,
    language: language,
    chipsList: chipsList,
    onSelectExtensions: onSelectExtensions,
    onSelectApplications: onSelectApplications,
    onClearProducts: onClearProducts,
    onClearFilters: onClearFilters,
    onPriceChipUnCheck: onPriceChipUnCheck,
  };

  let resultText;
  if (pageType === PAGE_NAME.SEARCH) {
    resultText = isFilterOn
      ? t('search:filteredResultText', { totalCount: numberWithCommas(totalCount) as never, filteredCount: numberWithCommas(filterCount) })
      : t('search:resultText', {
          count: numberWithCommas(totalCount) as never,
        });
  } else if (pageType === PAGE_NAME.BRAND) {
    resultText = isFilterOn
      ? t('brand:filteredResultText', { totalCount: numberWithCommas(totalCount) as never, filteredCount: numberWithCommas(filterCount) })
      : t('brand:resultText', {
          result: numberWithCommas(totalCount) as never,
        });
  } else {
    resultText = isFilterOn
      ? t('category:filteredResultText', { totalCount: numberWithCommas(totalCount) as never, filteredCount: numberWithCommas(filterCount) })
      : t('category:resultText', {
          count: numberWithCommas(totalCount) as never,
        });
  }

  return (
    <Styled.ProductFilterWrapper>
      <FiltersFlex>
        <div className={'tabs-wrapper'}>
          <ExtensionsFilter {...extensionsFilterProps} />
          <ApplicationsFilter {...applicationsFilterProps} />
          <PriceFilter {...priceFilterProps} />
        </div>
        {chipsList.length > 0 && <Chips {...chipsProps} />}
      </FiltersFlex>
      {/** 작가명 검색 페이지에서만 보임*/}
      {pageType === PAGE_NAME.SEARCH && brandName && brandCode && <SearchBrand name={brandName} code={brandCode} />}
      <SortFlex>
        <ResultCountFlex>
          {/* keyword는 검색 페이지에서만 보임 */}
          {pageType === PAGE_NAME.SEARCH && (
            <CustomText weight={700} lineHeight={'24px'} color={'#212B36'}>
              {keyword}
            </CustomText>
          )}
          <CustomText weight={500} lineHeight={'24px'} color={'#212B36'}>
            {resultText}
          </CustomText>
        </ResultCountFlex>
        <Sort onSelectSortOrder={onSelectSortOrder} sort={selectedSortOrder} pageType={pageType} />
      </SortFlex>
    </Styled.ProductFilterWrapper>
  );
}
