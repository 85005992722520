import { SORT_CODE } from '@components/filter/constants';
import { LanguageCodeEnum, ProductCardsWithBrandDocument } from 'src/generated/graphql';
import { SummaryProducts } from './type';

export const filterCategory = (products: SummaryProducts, category: string) => {
  return products.filter((product) => {
    const categoryCodeSet = new Set(product.categoryCode);
    return categoryCodeSet.has(category);
  });
};

export const filterExtension = (products: SummaryProducts, selectedExtensions: string[]) => {
  const filteredProducts = [];
  const filterSet = new Set(selectedExtensions);
  for (const product of products) {
    if (product.extensions.some((extension) => filterSet.has(extension))) {
      filteredProducts.push(product);
    }
  }
  return filteredProducts;
};

export const filterApplication = (products: SummaryProducts, selectedApplications: string[]) => {
  const filteredProducts = [];
  const filterSet = new Set(selectedApplications);
  for (const product of products) {
    if (product.applications.some((applicationObj) => filterSet.has(applicationObj.name))) {
      filteredProducts.push(product);
    }
  }
  return filteredProducts;
};

export const filterPrice = (products: SummaryProducts, minPrice: number | null, maxPrice: number | null, convertPrice: (price: number) => number) => {
  if (!minPrice && !maxPrice) return products;
  if (!minPrice && Boolean(maxPrice)) {
    return products.filter((product) => convertPrice(product.salePrice) <= maxPrice);
  } else if (Boolean(minPrice) && !maxPrice) {
    return products.filter((product) => minPrice <= convertPrice(product.salePrice));
  }
  return products.filter((product) => minPrice <= convertPrice(product.salePrice) && convertPrice(product.salePrice) <= maxPrice);
};

export const sortProducts = (products: SummaryProducts, sortOrder: string, isFreeSort: boolean) => {
  return products.sort((a, b) => {
    if (isFreeSort && Boolean(a.salePrice) !== Boolean(b.salePrice)) return a.salePrice ? -1 : 1;
    switch (sortOrder) {
      case SORT_CODE.ACCURACY:
        return b.score - a.score;
      case SORT_CODE.POPULARITY:
        return b.orderCount - a.orderCount;
      case SORT_CODE.NEWEST:
        return b.id - a.id;
      case SORT_CODE.LOWER_PRICE:
        return a.salePrice - b.salePrice;
      case SORT_CODE.HIGHER_PRICE:
        return b.salePrice - a.salePrice;
    }
    return 0;
  });
};

export const productCardQuery = (productNos: number[], languageCode: LanguageCodeEnum) => {
  return {
    query: ProductCardsWithBrandDocument,
    variables: {
      productNos,
      languageCode,
    },
  };
};

export const filterAdult = (products: SummaryProducts, isAdult: boolean) => {
  if (isAdult) return products;
  return products.filter((product) => !product?.isAdultOnly);
};
