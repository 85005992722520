import React from 'react';
import { useTranslation } from 'next-i18next';
import { CustomLink, CustomText } from '@components/ui';
import { RightArrowIcon } from 'src/assets/icons';
import BrandHomeIcon from 'src/assets/icons/BrandHomeIcon';
import { SearchBrandContainer, SearchBrandLinkWrapper } from './styled';

export default function SearchBrand({ name, code }) {
  const { t } = useTranslation(['search']);

  return (
    <SearchBrandContainer>
      <BrandHomeIcon size={24} />
      <CustomLink href={`/brand/${code}`}>
        <SearchBrandLinkWrapper>
          <CustomText size={16} weight={600} lineHeight={'24px'}>
            {name}
          </CustomText>
          <div className={'findCreator'}>
            <CustomText size={13} weight={400} lineHeight={'16px'}>
              {t('search:findCreator')}
            </CustomText>
            <RightArrowIcon size={16} />
          </div>
        </SearchBrandLinkWrapper>
      </CustomLink>
    </SearchBrandContainer>
  );
}
