import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { CustomText } from '@components/ui';
import { IButtonProps } from '@components/search/common/common.types';
import { DropDown, FilterButton } from '@components/search/common/common';
import { IInputProps, IPriceFilterProps, IRadioProps } from '@components/search/filters/PriceFilter/PriceFilter.types';
import { CheckedRadioIcon, UncheckedRadioIcon } from '@components/search/icons';
import { FilterWrapper } from '@components/search/common/common.styles';
import useDropdown from '@hooks/useDropdown';
import { DropdownContentWrapper, FilterApplyButton, RadioLabel } from './PriceFilter.styles';
import { buttonGap, getDropdownProps } from './PriceFilter.constants';

const Input = (props: IInputProps) => {
  const priceUnit = props.language === 'ko' ? '원' : props.language === 'ja' ? '円' : props.language === 'zh' ? '¥' : '$';
  const handleChange = (e) => {
    e.preventDefault();
    const valueToCheck = e.target.value.replaceAll(',', '');
    if (isNaN(valueToCheck)) return;
    props.onSelectPriceRangeId(null);
    props.onValueChange(Number(valueToCheck));
  };

  return props.language === 'ko' ? (
    <div className={'input-wrapper'}>
      <input type="text" step={10} value={props.value ? props.value : ''} onChange={handleChange} />
      <CustomText weight={600} lineHeight={'20px'} color={'#333333'}>
        {priceUnit}
      </CustomText>
    </div>
  ) : (
    <div className={'input-wrapper'}>
      <CustomText weight={600} lineHeight={'20px'} color={'#333333'}>
        {priceUnit}
      </CustomText>
      <input type="text" step={10} value={props.value ? props.value : ''} onChange={handleChange} />
    </div>
  );
};

const Radio = (props: IRadioProps) => {
  const { i18n } = useTranslation(['search']);
  const convertPrice = (price: number | null) => {
    if (price === null) return price;
    const divisor = i18n.language === 'ko' ? 1 : i18n.language === 'ja' ? 10 : i18n.language === 'zh' ? 100 : 1000;
    return price / divisor;
  };
  return (
    <RadioLabel>
      <input
        type="checkbox"
        onChange={() => {
          if (props.selectedPriceRangeId === props.id) {
            props.onSelectPriceRangeId(null);
            props.onSelectMinPrice(null);
            props.onSelectMaxPrice(null);
          } else {
            props.onSelectPriceRangeId(props.id);
            props.onSelectMinPrice(convertPrice(props.minPrice));
            props.onSelectMaxPrice(convertPrice(props.maxPrice));
          }
        }}
      />
      <span className={'radio'}>{props.isActive ? <CheckedRadioIcon /> : <UncheckedRadioIcon />}</span>
      <CustomText weight={400} lineHeight={'20px'} className={'label-text'}>
        {props.labelText}
      </CustomText>
    </RadioLabel>
  );
};

const PriceFilter = (props: IPriceFilterProps) => {
  const { buttonRef, dropdownRef, isDropdownOpen, handleButtonClick } = useDropdown();
  const [dropdownProps, setDropdownProps] = useState(null);

  const { t, i18n } = useTranslation(['search']);
  const convertPrice = (price: number) => {
    const divisor = i18n.language === 'ko' ? 1 : i18n.language === 'ja' ? 10 : i18n.language === 'zh' ? 100 : 1000;
    return price / divisor;
  };

  const priceRangeCol = [
    { id: 0, price1: null, price2: 5000, text: t('search:priceRanges.lower', { price: convertPrice(5000).toLocaleString('ko-KR') }) },
    {
      id: 1,
      price1: 5000,
      price2: 10000,
      text: t('search:priceRanges.between', { price1: convertPrice(5000).toLocaleString('ko-KR'), price2: convertPrice(10000).toLocaleString('ko-KR') }),
    },
    {
      id: 2,
      price1: 10000,
      price2: 30000,
      text: t('search:priceRanges.between', { price1: convertPrice(10000).toLocaleString('ko-KR'), price2: convertPrice(30000).toLocaleString('ko-KR') }),
    },
    {
      id: 3,
      price1: 30000,
      price2: 50000,
      text: t('search:priceRanges.between', { price1: convertPrice(30000).toLocaleString('ko-KR'), price2: convertPrice(50000).toLocaleString('ko-KR') }),
    },
    {
      id: 4,
      price1: 50000,
      price2: 70000,
      text: t('search:priceRanges.between', { price1: convertPrice(50000).toLocaleString('ko-KR'), price2: convertPrice(70000).toLocaleString('ko-KR') }),
    },
    {
      id: 5,
      price1: 70000,
      price2: 100000,
      text: t('search:priceRanges.between', { price1: convertPrice(70000).toLocaleString('ko-KR'), price2: convertPrice(100000).toLocaleString('ko-KR') }),
    },
    { id: 6, price1: 100000, price2: null, text: t('search:priceRanges.higher', { price: convertPrice(100000).toLocaleString('ko-KR') }) },
  ];

  const minInputProps: IInputProps = {
    language: i18n.language,
    onSelectPriceRangeId: props.onSelectPriceRangeId,
    onValueChange: props.onSelectTmpMinPrice,
    value: props.tmpMinPrice ? props.tmpMinPrice.toLocaleString('ko-KR') : '',
  };

  const maxInputProps: IInputProps = {
    language: i18n.language,
    onSelectPriceRangeId: props.onSelectPriceRangeId,
    onValueChange: props.onSelectTmpMaxPrice,
    value: props.tmpMaxPrice ? props.tmpMaxPrice.toLocaleString('ko-KR') : '',
  };

  const isButtonDisabled =
    (Boolean(props.tmpMinPrice) && Boolean(props.tmpMaxPrice) && props.tmpMinPrice > props.tmpMaxPrice) ||
    (props.tmpMinPrice === props.selectedMinPrice && props.tmpMaxPrice === props.selectedMaxPrice);

  const dropdownContent = (
    <DropdownContentWrapper>
      <div className={'price-input-wrapper'}>
        <div className={'price-input-content'}>
          <Input {...minInputProps} />
          <svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="1" x2="10" y2="1" stroke="#DFDFDF" strokeWidth="2" />
          </svg>
          <Input {...maxInputProps} />
        </div>

        <FilterApplyButton
          disabled={isButtonDisabled}
          isDisabled={isButtonDisabled}
          onClick={() => {
            // if (!props.tmpMinPrice && !props.tmpMaxPrice && !props.selectedMinPrice && !props.selectedMaxPrice) return;
            props.onSelectMinPrice(props.tmpMinPrice);
            props.onSelectMaxPrice(props.tmpMaxPrice);
            handleButtonClick();
          }}
        >
          <CustomText weight={600} lineHeight={'20px'} color={isButtonDisabled ? '#A2A2A4' : '#FFFFFF'}>
            {t('search:apply')}
          </CustomText>
        </FilterApplyButton>
      </div>
      <div className={'checkboxes-wrapper'}>
        <div className={'checkboxes-col'}>
          {priceRangeCol.slice(0, 4).map((priceRange) => {
            const radioProps: IRadioProps = {
              labelText: priceRange.text,
              onSelectPriceRangeId: props.onSelectPriceRangeId,
              id: priceRange.id,
              isActive: props.selectedPriceRangeId === priceRange.id,
              minPrice: priceRange.price1,
              maxPrice: priceRange.price2,
              onSelectMinPrice: props.onSelectTmpMinPrice,
              onSelectMaxPrice: props.onSelectTmpMaxPrice,
              selectedPriceRangeId: props.selectedPriceRangeId,
            };
            return <Radio key={priceRange.id} {...radioProps} />;
          })}
        </div>
        <div className={'checkboxes-col'}>
          {priceRangeCol.slice(4).map((priceRange) => {
            const radioProps: IRadioProps = {
              labelText: priceRange.text,
              onSelectPriceRangeId: props.onSelectPriceRangeId,
              id: priceRange.id,
              isActive: props.selectedPriceRangeId === priceRange.id,
              minPrice: priceRange.price1,
              maxPrice: priceRange.price2,
              onSelectMinPrice: props.onSelectTmpMinPrice,
              onSelectMaxPrice: props.onSelectTmpMaxPrice,
              selectedPriceRangeId: props.selectedPriceRangeId,
            };
            return <Radio key={priceRange.id} {...radioProps} />;
          })}
        </div>
      </div>
    </DropdownContentWrapper>
  );

  const buttonHeight = 36;

  const filterButtonProps: IButtonProps = {
    height: buttonHeight,
    isActive: Boolean(props.selectedMinPrice) || Boolean(props.selectedMaxPrice) || isDropdownOpen,
    onClick: handleButtonClick,
  };

  useEffect(() => {
    if (buttonRef.current) {
      const { x, y, width } = buttonRef.current.getBoundingClientRect();
      const positionX = window.innerWidth / 2 > x + width ? x : 16;
      const positionY = y + buttonHeight + buttonGap;
      setDropdownProps(getDropdownProps(positionX, positionY));
    }
  }, [isDropdownOpen]);

  return (
    <FilterWrapper>
      <FilterButton ref={buttonRef} {...filterButtonProps}>
        {t('search:filters.price')}
      </FilterButton>
      {isDropdownOpen && (
        <DropDown ref={dropdownRef} {...dropdownProps}>
          {dropdownContent}
        </DropDown>
      )}
    </FilterWrapper>
  );
};

export default PriceFilter;
