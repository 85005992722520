import styled from 'styled-components';
import { ButtonBase, DropdownWrapper } from '@components/search/common/common.styles';

export const SortButtonBase = styled(ButtonBase)`
  padding: 6px 5px;
  border: none;
  background-color: #ffffff;
`;

export const SortWrapper = styled.div`
  position: relative;
`;

export const SortDropdownWrapper = styled(DropdownWrapper)`
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
  padding: 0px;
  position: absolute;
  gap: 0px;
  top: ${(props) => props.offsetTop}px;
  right: 0px;
  width: auto;
`;

export const SortOrderButton = styled.button<{ isActive?: boolean }>`
  height: 32px;
  width: 100%;
  padding: 6px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${(props) => (props.isActive ? 'rgba(78, 78, 255, 0.04)' : '#FFFFFF')};
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`;
